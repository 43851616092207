import * as React from 'react';
import { Modal, Form, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { FolderForm } from "../../form/FolderForm";
import { useState } from "react";

function FolderModal(props: {
  visible: boolean;
  form?: FolderForm,
  onCancel: () => void,
  onConfirm: (form?: FolderForm) => void
}) {

  const { visible, form, onCancel, onConfirm } = props;

  const [f, setF] = useState(form ? form : new FolderForm(''));
  return (
    <Modal
      title={'新建文件夹'}
      visible={visible}
      okText={'确认'}
      cancelText={'取消'}
      onCancel={() => onCancel()}
      onOk={() => { onConfirm(f); console.log(f)}}
    >
      <Form
        layout={'vertical'}
        name="basic"
      >
        <Form.Item
          label="文件夹名称"
          required
        >
          <Input
            value={f?.name}
            onChange={(e) => setF(f?.amend('name', e.currentTarget.value))}
          />
        </Form.Item>
        <Form.Item
          label="文件夹描述"
        >
          <TextArea
            value={f?.description}
            onChange={(e) => setF(f?.amend('description', e.currentTarget.value))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default FolderModal;
