import React, { useCallback, useContext, useEffect, useState } from 'react';
import { WhitePhoneRepository } from "../../repository/WhitePhoneRepository";
import { WhitePhone } from "../../model/WhitePhone";
import { Button, Table } from 'antd'
import WhiteModal from "./WhiteModal";

function WhitePhoneIndex() {
  const repo = useContext(WhitePhoneRepository.Context);

  const [ phones, setPhones ] = useState<WhitePhone[]>([]);
  const [ visible, setVisible ] = useState(false);

  const find = useCallback(() => {
    repo.findAll().then(p => setPhones(p));
  }, [repo]);

  const remove = (id:  number) => {
    repo.remove(id).then(() => find());
  };

  const persist = (mobile: string) => {
    repo.persist(mobile).then(p => find());
    setVisible(false);
  };

  useEffect(() => {
    find();
  }, [ find ]);
  return (<div>
    <Button onClick={() => setVisible(true)}>添加</Button>

    <Table
      columns={
        [
          {title: 'id', key: 'id', dataIndex: 'id'},
          {title: '作品', key: 'mobile', dataIndex: 'mobile'},
          {title: '操作', key: 'actions', dataIndex: 'actions'},
        ]
      }
      dataSource={phones.map(w => {
        return {
          id: w.id,
          mobile: w.phone,
          actions: (<div>
            <Button onClick={() => remove(w.id)} type={'link'}>删除</Button>
          </div>)
        }
      })}
    />

    <WhiteModal visible={visible} onCancel={() => setVisible(false)} onConfirm={(mobile) => persist(mobile)}/>
  </div>);
}

export default WhitePhoneIndex;
