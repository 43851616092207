
export class Manager {

  static fromJSON(data: ManagerJSON) {
    return new Manager(
      data.id,
      data.username,
        data.password,
    );
  }

  constructor(
    public readonly id: number,
    public readonly username: string,
    public readonly password: string,
  )  {

  }
}

export interface ManagerJSON {
  readonly id: number;
  readonly username: string;
  readonly password: string;
}
