export class Fragment<T> {
  public static fromJSON<U, V>(data: FragmentJSON<U>, f: (x: U) => V) {
    return new Fragment(
      data.data.map(f),
      parseInt(String(data.total_quantity), 10)
    );
  }

  constructor(
    public readonly data: T[] = [],
    public readonly total: number = 1
  ) {
  }
}

export interface FragmentJSON<T> {
  readonly data: T[];
  readonly total_quantity: number;
}
