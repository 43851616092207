
export class EBookForm {

  constructor(
    public readonly name: string = '',
    public readonly id?: number
  ) {

  }

  amend<K extends keyof EBookForm>(key: K, value: EBookForm[K]) {

    const { name, id } = Object.assign({}, this, {[key]: value});

    return new EBookForm(name, id);
  }

  flush() {
    return ({
      name: this.name
    });
  }
}
