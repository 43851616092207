
export class FolderForm  {

  constructor(
    public readonly name: string = '',
    public readonly description?: string
  ) {

  }

  amend<K extends keyof FolderForm>(key: K, value: FolderForm[K]) {

    const { name, description } = Object.assign({}, this, {[key]: value});

    return new FolderForm(name, description);
  }

  flush() {
    return ({
      name: this.name,
      description: this.description
    });
  }

}
