import { EBook, EBookJSON } from "./EBook";

export type dtype = 'pageaudio' | 'pagecatalog';

export class Page {

  static fromJSON(data: PageJSON): any {
    switch (data.dtype) {
      case "pageaudio":
        return PageAudio.fromJSON(data as PageAudioJSON);
      case "pagecatalog":
        return PageCatalog.fromJSON(data as PageCatalogJSON);
    }
  }
  constructor(
    public readonly id: number
  ) {

  }

}


export interface PageJSON {
  readonly id: number;
  readonly number: number;
  readonly dtype: dtype;
}


export class PageAudio {

  static fromJSON(data: PageAudioJSON) {

    return new PageAudio(
      data.id,
      data.title,
      data.cover,
      data.audio,
      data.number,
      data.duration || 126,
      data.ref_link,
      data.book ? EBook.fromJSON(data.book) : undefined,
      data.parent ? Page.fromJSON(data.parent) : undefined,
      data.children ? data.children.map(Page.fromJSON) : [],
      data.pid
    );
  }

  constructor(
    public readonly id: number,
    public readonly title: string,
    public readonly cover: string,
    public readonly audio: string,
    public readonly number: number,
    public readonly duration?: number,
    public readonly refLink?: string,
    public readonly book?: EBook,
    public readonly parent?: PageCatalog | PageAudio,
    public readonly children?: Page[],
    public readonly pid?: number
  ) {

  }

  flatten(folder?: PageAudio | PageCatalog): any {
    let t = folder ? folder : this;
    return [t].concat( ( t.parent ? t.flatten(t.parent) : []));
  }

}

export interface PageAudioJSON extends PageJSON {
  readonly id: number;
  readonly number: number;
  readonly dtype: dtype;
  readonly title: string;
  readonly cover: string;
  readonly audio: string;
  readonly duration?: number;
  readonly ref_link?: string;
  readonly book?: EBookJSON;
  readonly parent?: PageJSON;
  readonly children?: PageJSON[];
  readonly pid?: number;
}


export class PageCatalog {


  static fromJSON(data: PageCatalogJSON) {
    return new PageCatalog(
      data.id,
      data.number,
      data.name,
      data.parent ? Page.fromJSON(data.parent) : undefined,
      data.pid,
      data.children?.length ? data.children.map(Page.fromJSON) : []
    );
  }

  constructor(
    public readonly id: number,
    public readonly number: number,
    public readonly name: string,
    public readonly parent?: PageAudio | PageCatalog,
    public readonly pid?: number,
    public readonly children?: Page[]
  ) {

  }

  flatten(folder?: PageAudio | PageCatalog): any {
    let t = folder ? folder : this;
    return [t].concat( ( t.parent ? t.flatten(t.parent) : []));
  }

}

export interface PageCatalogJSON extends  PageJSON {
  readonly id: number;
  readonly number: number;
  readonly dtype: dtype;
  readonly name: string;
  readonly parent?: PageJSON;
  readonly children?: PageJSON[];
  readonly pid: number;
}
