import moment, { Moment } from "moment";
import { EBookForm } from "../form/EBookForm";
import { Page, PageAudio, PageAudioJSON, PageJSON } from "./Page";

export class EBook extends EBookForm {

  static fromJSON(data: EBookJSON): EBook {
    return new EBook(
      data.id,
      data.name,
      moment(data.created_at),
      data.pages ? data.pages.map(Page.fromJSON) : [],
      data.audio_pages ? data.audio_pages.map(PageAudio.fromJSON) : []
    );
  }

  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly createdAt: Moment,
    public readonly pages: Page[],
    public readonly audioPages: PageAudio[]
  ) {
    super(name, id);
  }


}

export interface EBookJSON {
  readonly id: number;
  readonly name: string;
  readonly created_at: string;
  readonly pages?: PageJSON[]
  readonly audio_pages?: PageAudioJSON[];
}
