import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet';

import './index.css';
import { Button, Slider, Drawer, Menu } from "antd";
import EBookRepository from "../../../repository/EBookRepository";
import { EBook } from "../../../model/EBook";
import { useParams } from "react-router";
import { PageAudio, PageCatalog } from "../../../model/Page";
import { imgUrl } from "../../../model";
import { useHistory } from "react-router";

export function EBookIndex () {
  const repo = useContext(EBookRepository.Context);
  const [ book, setBook ] = useState<EBook | undefined>(undefined);
  const [ pageAudio, setPage ] = useState<PageAudio | undefined>(undefined);
  const [ visible, setVisible ] = useState(false);
  const [ seconds, setSeconds ] = useState<undefined | number>(undefined);
  const [ play, setPlay ] = useState(false);
  const [ index, setIndex ] = useState<number | undefined>(undefined);
  const [ openkey, setOpenKey ] = useState<string[]>([]);
  const { id, page } = useParams();
  const history = useHistory();
  let interval: any = null;

  useEffect(() => {
    repo.find(+id)
      .then(b => {
        setBook(b);
        setPage((b.audioPages.find(p => p?.id === +page && p instanceof PageAudio)) as PageAudio);
        setIndex(b.audioPages.findIndex(p => p?.id === +page));
      });
  }, [repo, id, page]);

  const playAudio = () => {

    if (play) {
      setPlay(false);
      (document.getElementById('audio-player') as HTMLAudioElement )?.pause();
      if (interval) {
        clearTimeout(interval);
      }

    } else {
      setPlay(true);
      (document.getElementById('audio-player') as HTMLAudioElement )?.play();
      if (seconds === undefined) {
        setSeconds(0);
      }
    }
  };

  const switchPage = (p: PageAudio) => {
    setPlay(false);
    setPage(p);
    interval && clearTimeout(interval);
    (document.getElementById('audio-player') as HTMLAudioElement )?.pause();
    setSeconds(undefined);
    setVisible(false);
    setIndex(book?.audioPages.findIndex(pp => pp.id === p.id));
    (document.getElementById('audio-player') as HTMLAudioElement ).currentTime = 0;
    setOpenKey([p.pid?.toString() || ''])


  };

  useEffect(() => {
    if (pageAudio?.duration && seconds !== undefined &&play && seconds <= pageAudio?.duration) {
      interval = setTimeout(() => {
        setSeconds((seconds || 0) + 1);
      }, 1000);
    } else {
      interval && clearTimeout(interval);
      setSeconds(undefined);
      setPlay(false);
    }
  }, [ seconds, pageAudio, play ]);

  const onAflterChange = (value: number) => {
    interval && clearTimeout(interval);
    setSeconds(value);
    setPlay(true);
    (document.getElementById('audio-player') as HTMLAudioElement ).currentTime = value;
    (document.getElementById('audio-player') as HTMLAudioElement ).play();
  };


  const onChange = (value: number) => {
    setSeconds(value)
    setPlay(false);
    interval && clearTimeout(interval);
  };

  const pre = () => {
    setPlay(false);
    (document.getElementById('audio-player') as HTMLAudioElement )?.pause();
    (document.getElementById('audio-player') as HTMLAudioElement ).currentTime = 0;
    let index = book?.audioPages.findIndex(p => p.id === pageAudio?.id);
    index = index && index >= 1 ? index - 1 : 0;
    let c = book?.audioPages[index];
    console.log(c);
    if (index !== undefined) {
      setIndex(index);
    }
    setPage(c as PageAudio);
    setSeconds(undefined);
    interval && clearTimeout(interval);
    setOpenKey([]);
  };

  const next = () => {
    setPlay(false);
    (document.getElementById('audio-player') as HTMLAudioElement )?.pause();
    (document.getElementById('audio-player') as HTMLAudioElement ).currentTime = 0;
    let index = book?.audioPages.findIndex(p => p.id === pageAudio?.id);
    console.log(index);
    if (book?.audioPages) {
      index = index !== undefined && book && book.audioPages &&  index < (book?.audioPages.length -1 ) ?  index + 1 : book?.audioPages.length -1;
      let c = book?.audioPages[index];
      console.log(c);
      if (c) {
        index && setIndex(index);
        setPage(c as PageAudio);
        setSeconds(undefined);
        interval && clearTimeout(interval);
      }
      setOpenKey([]);
    }
  };
  const backlist=()=>{
    var name = book?.name;
    var level = name?.split('_')[1].split('-')[0];
    var pid = Number(name?.split('_')[1].split('-')[1])-1;
    history.push('/m/e-book/english'+level+'/'+pid);
  };
  const getlevel=(pageAudio:PageAudio|undefined)=>{
     
    // console.log(book,pageAudio);
   
    if(pageAudio){
      var new_pages = new Array(); 
      book?.pages.map((item)=>{
        new_pages.push(item);
        if(item instanceof PageCatalog){
          item.children?.map((item1)=>{
            new_pages.push(item1)
            if(item1 instanceof PageCatalog){
              item1.children?.map((item2)=>{
                new_pages.push(item2)
                
              });
            }
          });
        }
        
      })
      // console.log(new_pages,typeof(new_pages))
 
      var parent =  new_pages.find(p=>p.id==pageAudio.pid)
      var parent_parent = new_pages.find(p=>p.id==parent.pid)
      // console.log(pageAudio.id,parent.id,parent_parent.id);
      if(parent_parent){
         
        return parent.name;
      }
      
    }
 
    return '';
  }
  const handleClose=()=>{
    setVisible(false)
  }
  return (
    <div className={'book-con'} style={{backgroundColor:`#ffe33e`}}>
        <Helmet title={book?.name}/>
        <Button type={'link'} onClick={() => setVisible(true)} className={'menu'}>
          <img src={'/m_imgs2/menu.png'} />
        </Button>
        { book?.name.indexOf('_')!=-1  && book?.name.indexOf('-')!=-1  && <Button type={'link'} onClick={() => backlist()}  className={'backbtn'} >
          <img src={'/m_imgs2/back.png'}/>
        </Button>}
      
      <div className={'pages-con'}>
      <div className="headbg">
        <div className={'bigtitle'}>
          {getlevel(pageAudio)}
        </div> 
      </div>
        <div className="zhanwei"></div>
       

        <div className={'thumb'}>
          <img src={pageAudio?.cover ? imgUrl(pageAudio?.cover) : ''} />
          
        </div>
        {pageAudio?.title?.length && pageAudio?.title?.length>10 &&
            <div className={'marquee-container '}>
            <div className="marquee-content title" data-text={pageAudio?.title}>
            {pageAudio?.title}
            </div>
             
            </div>
        }

        {pageAudio?.title?.length && pageAudio?.title?.length<=10 &&
             
            <div className=" title">
            {pageAudio?.title}
            </div>

            
        }

 
       
        <div className={'control'}>
          <div className={'duration'}>
            <span>{moment.utc((seconds || 0) * 1000).format('mm:ss')}</span>
            <div className={'progress'}>
              {pageAudio?.duration &&  <Slider value={seconds || 0} defaultValue={seconds || 0} max={pageAudio?.duration || 0} disabled={false} onChange={onChange} onAfterChange={onAflterChange}/>}
            </div>
            <span>{pageAudio?.duration && moment.utc(pageAudio?.duration * 1000).format('mm:ss')}</span>
          </div>
          <div className={'buttons'}>
            <span onClick={() => pre()} className={`${index === 0 ? 'disable' : ''}`}><img src={'/m_imgs2/m_pre.png'} alt={''}/></span>
            <span onClick={() => playAudio()}><img src={play ? '/m_imgs2/m_pause.png' : '/m_imgs2/play.png'} alt={''} className={'big'}/></span>
            <span onClick={() => next()} className={`${book && index === book?.audioPages.length - 1 ? 'disable' : ''}`}><img src={'/m_imgs2/m_next.png'} alt={''}/></span>
          </div>
        </div>
        {pageAudio && <audio src={imgUrl(pageAudio?.audio)} autoPlay={false} id={'audio-player'}/>}
        <Drawer
          placement={'left'}
          closable={true}
          // onClose={() => {setVisible(false)}}
          visible={visible}
          key={'outlines'}
          className={'outlines-drawer'}
          width={'90%'}
        >
          
          <div className="close_btn" onClick={handleClose}>
            <img src="/m_imgs2/close.png" alt="关闭按钮"   />
          </div>
          <div className={'title'}>
            <img src={'/m_imgs2/tit.png'}/>
          </div>
          <div className={'outlines'}>
            {/*{book?.pages.map((p, index) => {*/}
            {/*  if (p instanceof PageAudio) {*/}
            {/*    return  <p onClick={() => switchPage(p)}><span> </span>{p.title}</p>;*/}
            {/*  }*/}
            {/*  if (p instanceof PageCatalog) {*/}
            {/*    return  <div>*/}
            {/*      <p><span> </span>{p.name}</p>*/}
            {/*      {p.children?.map((pp, index2) => {*/}
            {/*        if (pp instanceof PageAudio) {*/}
            {/*          return  <p onClick={() => switchPage(pp)} style={{paddingLeft: '10px'}} className={`${pp?.id === pageAudio?.id ? 'current' : ''}`}><span> </span>{(pp as PageAudio)?.title}</p>;*/}
            {/*        }*/}
            {/*        return <></>*/}
            {/*      })}*/}
            {/*    </div>;*/}
            {/*  }*/}
            {/*  return <></>*/}
            {/*})}*/}
            <Menu
              mode="inline"
              openKeys={openkey.length > 0 ? openkey : [pageAudio?.pid?.toString() || '']}
              selectedKeys={[pageAudio?.id.toString() || '']}

            >
              {book?.pages.map((p, index) => {
                if (p instanceof PageAudio) {
                 return <Menu.Item onClick={() => switchPage(p)}>
                   {p.title}
                 </Menu.Item>
                }
                if (p instanceof PageCatalog) {
                  
                  return <Menu.SubMenu title={p.name} key={p.id} style={{fontSize:'larger'}} onTitleClick={() => setOpenKey([p.id.toString()])} >
                    {p?.children?.map((pp, index2) => {
                      if (pp instanceof PageAudio) {
                        return <Menu.Item onClick={() => switchPage(pp)} key={pp.id} style={{paddingLeft: '24px !important'}}>
                          <span style={{color: 'transparent'}}>{p.name.indexOf('：') !== -1 ? p.name.split("：")[0] : ''}</span>{pp.title}
                        </Menu.Item>
                      }
                  
                      if (pp instanceof PageCatalog) {
                      return <Menu.SubMenu title={pp.name} key={pp.id} onTitleClick={() => setOpenKey([p.id.toString(),pp.id.toString()])}  >
                        {pp?.children?.map((ppp, index3) => {
                              console.log(ppp)
                          if (ppp instanceof PageAudio) {
                            return <Menu.Item onClick={() => switchPage(ppp)} key={ppp.id} style={{paddingLeft: '24px'}}>
                              <span style={{color: 'transparent'}}>{pp.name.indexOf('：') !== -1 ? pp.name.split("：")[0] : ''}</span>{ppp.title}
                            </Menu.Item>
                          }
                          
                          return <></>
                        })}
                      </Menu.SubMenu>
                      }
                    })}
                  </Menu.SubMenu>
                }
                return <></>
               })
              }


            </Menu>

          </div>
        </Drawer>
      </div>

    </div>
  );
}
