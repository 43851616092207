import { BookForm } from "../form/BookForm";
import { BookCase, BookCaseJSON } from "./BookCase";
import moment, { Moment } from "moment";

export class Book extends BookForm{

  static fromJSON(data: BookJSON): Book
  {
    return new Book(
      data.id,
      data.name,
      data.description,
      data.attachment,
      data.cupboard ? BookCase.fromJSON(data.cupboard) : undefined,
      data.created_at ? moment(data.created_at) :  undefined,
      data.resolved_at ? moment(data.resolved_at) : undefined,
      data.audio,
      data.sequence
    );
  }

  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly description?: string,
    public readonly attachment?: string,
    public readonly bookCase?: BookCase,
    public readonly createdAt?:  Moment,
    public readonly resolvedAt?: Moment,
    public readonly audio?: string,
    public readonly sequence?: number
  ) {
    super(name, description, attachment, bookCase, audio, sequence);
  }
}

export interface BookJSON {
  readonly id: number;
  readonly name: string;
  readonly description?: string;
  readonly attachment?: string;
  readonly cupboard?: BookCaseJSON;
  readonly created_at?: string;
  readonly resolved_at?: string;
  readonly audio?: string;
  readonly sequence?: number;
}
