import * as React from 'react';
import { Button } from "antd";
import { Link } from 'react-router-dom';

import './index.css';
import { useCallback, useContext, useEffect, useState } from "react";
import { imgUrl, PageSizeContext } from "../../model";
import { Fragment } from "../../model/Fragment";
import BookCaseModal from "./BookCaseModal";
import { BookCaseRepository } from "../../repository/BookCaseRepository";
import { BookCase } from "../../model/BookCase";
import { BookCaseForm } from "../../form/BookCaseForm";

function BookCaseIndex() {
  const repo = useContext(BookCaseRepository.Context);
  const pageSize = useContext(PageSizeContext);

  // const [ page, setPage ] = useState(1);
  const page = 1;
  const [ visible, setVisible ] = useState(false);
  const [ f, setF ] = useState<Fragment<BookCase> | undefined>(undefined);
  const [ current, setCurrent ] = useState<BookCase | undefined>(undefined);

  const save = async (form: BookCaseForm, id?: number) => {
    await repo.persist(form, id);
    setVisible(false);
    setCurrent(undefined);
    find();
  };

  const find = useCallback(function () {
    repo
      .findAll(page, 999)
      .then(f => {
        setF(f);
      })
  }, [page, pageSize, repo]);

  const remove = async (id: number) => {
    await repo.remove(id);
    find();
  };

  useEffect(()=> {
    find();
  } , [find]);

  return (
    <div className="folder-index">
      <div className={'title'}>
        我的书橱
      </div>
      <div className={'content'}>
        <div className={'tool'}>
          <Button className={'link'} type={'primary'} onClick={() => {setVisible(true); setCurrent(undefined);}}>新建书橱</Button>
        </div>

        <div className="list">
          {f && f.data.map(p => {
            return (
              <Link className="folder" to={`/book-case/${p.id}`}>
                <div className={'icon'}>
                  <img src={p.thumb ? imgUrl(p.thumb):  '/img/folder.svg'} alt={''}/>
                </div>
                <div className="c">
                  <div className={'name'}>{p.name}</div>
                  <div className={'description'}>{p.description}</div>
                  <div className={'more'} >
                    <Button onClick={(e) => { e.preventDefault();setCurrent(p); setVisible(true);}} type={'link'}>编辑</Button>
                    <Button onClick={(e) => {e.preventDefault();remove(p.id)}} type={'link'}>删除</Button>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>

      </div>
      {visible &&  <BookCaseModal
          form={current}
          visible={visible}
          onCancel={() => { setVisible(false);}}
          onConfirm={(form) => { form && save(form, current ? current.id : undefined);}}
      />}
    </div>
  );
}

export default BookCaseIndex;
