import React, { useCallback, useContext, useEffect, useState } from 'react';
import { AppstoreAddOutlined, EditOutlined, DeleteOutlined, QrcodeOutlined, BarsOutlined, ArrowRightOutlined }  from '@ant-design/icons';
import URI from "urijs";
import { Link } from "react-router-dom";
import './index.css';
import { Button, Modal, Spin, Table } from "antd";
import EBookRepository from "../../repository/EBookRepository";
import { EBook } from "../../model/EBook";
import { useHistory, useLocation, useParams } from "react-router";
import { PageAudioForm, PageCatalogForm } from "../../form/PageForm";
import { BookPageModal } from "../widget/BookPageModal";
import { Fragment } from "../../model/Fragment";
import { Page, PageAudio, PageCatalog } from "../../model/Page";
import { usePager } from "../widget/Paginator";
import { QrCodeModal } from "../widget/QrCodeModal";
import { CatalogModal } from "../widget/CatalogModal";

export function EBookPageIndex () {

  const repo = useContext(EBookRepository.Context);

  const [ page, size ] = usePager();
  const history = useHistory();

  const [ book, setBook ] = useState<EBook | undefined>(undefined);
  const [ spinning, setSpinning ] = useState(false);
  const [ form, setForm ] = useState<PageAudioForm | undefined>(undefined);
  const [ catalogForm, setCatalogForm ] = useState<PageCatalogForm | undefined>(undefined);
  const [ pages, setPages ] = useState<Fragment<PageAudio | PageCatalog> | undefined>(undefined);
  const [ url, setUrl ] = useState<string | undefined>(undefined);
  const [ p, setP ] = useState<PageAudio | PageCatalog | undefined>(undefined);

  const { id } = useParams();
  const { search } = useLocation();
  const query: any = URI.parseQuery(search);
  const parent = query['parent'] || undefined;

  const findPages = useCallback(async  () => {
    setSpinning(true);
    const d = await repo.findPages(id, page, size, parent);
    setPages(d);
    if (id && parent) {
      const p = await repo.findPage(id, parent);
      setP(p);
    } else {
      setP(undefined);
    }
    setSpinning(false);
  }, [page, size, repo, id, parent]);

  useEffect(() => {
    findPages();
  }, [ findPages ]);

  useEffect(() => {
    if (id) {
      setSpinning(true);
      repo.find(+id).then(b => {
        setBook(b);
        setSpinning(false);
      });
    }
  },  [id, repo, setBook]);

  const persist = async (form: PageAudioForm | PageCatalogForm) => {
    let f = undefined;
    if (form instanceof PageCatalogForm) {
      f = form.amend('book', book).amend('parent', parent);
    } else {
      f = form.amend('book', book).amend('parent', parent);
    }
    console.log(f);
    if (book) {
      setSpinning(true);
      await repo.persistPage(book?.id, f);
      findPages();
      setSpinning(false);
     
    }
    setForm(undefined);
  };

  const remove = async (id: number, page: number, name: string) => {
    Modal.confirm({
      title: '确认删除',
      content: '确认删除'+name,
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        repo.removePage(id, page).then(_ => findPages());
      }
    });
  };
console.log(p)

  return (
    <div className={'main-content'}>
      <div className={'padding'}>
       <Spin spinning={spinning}>
         <h3>书籍: {book?.name}</h3>

         <div className={'catalog'}>
         <Link to={`/e-book/index`}>电子书</Link>
         
           <Link to={`/e-book/${book?.id}/pages`} > <ArrowRightOutlined /> {book?.name}</Link>
           {p?.flatten().reverse().map((f: any) => (
            f.pid ?
              <Link to={`/e-book/${book?.id}/pages?parent=${f.pid}`}>  <ArrowRightOutlined /> {f.name}</Link>
            :
              <Link to={`/e-book/${book?.id}/pages`}>  <ArrowRightOutlined />返回上一页</Link>
             
           ))}
         </div>

         <div className={'tool-bar'}>
           <Button type={'link'} onClick={() => {setCatalogForm(new PageCatalogForm())}}><AppstoreAddOutlined />新建目录</Button>
           <Button type={'link'} onClick={() => {setForm(new PageAudioForm())}}><AppstoreAddOutlined />新建</Button>
         </div>

         <Table
           columns={[
             {
               title: 'ID',
               dataIndex: 'id',
               key: 'ID',
             },
             {
               title: '名称',
               dataIndex: 'name',
               key: 'name',
             },
             {
               title: '页码',
               dataIndex: 'number',
               key: 'number',
             },
             {
               title: '操作',
               dataIndex: 'actions',
               key: 'actions',
               align: 'right'
             },
           ]}
           dataSource={pages?.data.map((f) => {
             if (f instanceof PageAudio) {
               return {
                 id: f.id,
                 name: f.title,
                 number: f.number,
                 actions: <>
                   <Button type={'link'} onClick={() => setUrl(`/wheel/api/v1/e-book/${book?.id}/page/${f.id}/qrcode`)}><QrcodeOutlined />二维码</Button>
                   <Button type={'link'} onClick={() => setForm(PageAudioForm.fromPageAudio(f))}><EditOutlined />编辑</Button>
                   <Button type={'link'} onClick={() => remove(+id, f.id, f.title)}><DeleteOutlined />删除</Button>
                 </>
               }
             }
             if (f instanceof PageCatalog) {
               return {
                 id: f.id,
                 name: f.name,
                 number: f.number,
                 actions: <>
                   <Button type={'link'} onClick={() => history.push(`/e-book/${id}/pages?parent=${f.id}`)}><BarsOutlined />内容管理</Button>
                   <Button type={'link'} onClick={() => setCatalogForm(PageCatalogForm.fromPageCatalog(f))}><EditOutlined />编辑</Button>
                   <Button type={'link'} onClick={() => remove(+id, f.id, f.name)}><DeleteOutlined />删除</Button>
                 </>
               }
             }

             return <></>;
           })}
         />

         {form && <BookPageModal
             form={form}
             onCancel={() => setForm(undefined)}
             onOk={(form) => { persist(form)}}
         />}

         { url && <QrCodeModal value={url} onCancel={() => setUrl(undefined)} onOk={() => setUrl(undefined)}/>}

         {catalogForm && <CatalogModal
           form={catalogForm}
           onOk={(f) => {persist(f); setCatalogForm(undefined)}}
           onCancel={() => setCatalogForm(undefined)}
         />}
       </Spin>
      </div>
    </div>
  );
}
