import { Manager } from '../../model/Manager';
import { Dispatch } from 'redux';
import { SecurityRepository } from '../../repository/SecurityRepository';
import { MANAGER_FIND, MANAGER_LOGIN } from '../../constants/action';

export type ManagerState = Readonly<{
  manager: Manager | undefined | null
}>;

type Action = {
  type: string;
  manager: Manager | undefined | null
}

const initState: ManagerState = {
  manager: undefined
}

export function login(manager: Manager) {
  return (dispatch: Dispatch) => {
    dispatch(
      {
        type: MANAGER_LOGIN,
        manager: manager
      }
    )
  }
}

export function findManager() {
  return (dispatch: Dispatch) => {
    SecurityRepository.$.get().then(manager => dispatch({
      type: MANAGER_FIND,
      manager: manager
    })).catch(() => dispatch(({
      type: MANAGER_FIND,
      manager: null
    })))
  }
}

export default function (state: ManagerState = initState, action: Action) {
  switch (action.type) {
    case MANAGER_LOGIN:
      return {
        manager: action.manager
      };
    case MANAGER_FIND:
      return {
        manager: action.manager
      };
    default:
      return state;
  }
}