import axios, { AxiosInstance } from "axios";
import { createContext } from "react";
import { Fragment, FragmentJSON } from "../model/Fragment";
import { Folder, FolderJSON } from "../model/Folder";
import { FolderForm } from "../form/FolderForm";

export class FolderRepository {

  static $ = new FolderRepository(axios);
  static Context = createContext(FolderRepository.$);

  constructor(private a: AxiosInstance) {

  }

  async findAll(page: number = 1, size: number = 15) {
    const { data } = await this.a.get<FragmentJSON<FolderJSON>>('/folder', {params: { page, size }});

    return Fragment.fromJSON(data, Folder.fromJSON);
  }

  async persist(form: FolderForm, id?: number) {
    const { data } = id ? await this.a.put(`/folder/${id}`, { form: form.flush()}) : await this.a.post(`/folder`, { form: form.flush()});

    return Folder.fromJSON(data);
  }

  async remove(id: number) {
    await this.a.delete(`/folder/${id}`);
  }
}
