import * as React from 'react';
import { AppState } from '../../redux/rootReducer';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Button, Layout, Menu } from 'antd';
import { Manager } from '../../model/Manager';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { Link } from 'react-router-dom';
import { SecurityRepository } from '../../repository/SecurityRepository';
import { BookOutlined } from '@ant-design/icons'

import './home.css';
import Dashboard from "./Dashboard";
import FolderIndex from "../folder/FolderIndex";
import BookNew from "../book/BookNew";
import BookCaseIndex from "../book_case/BookCaseIndex";
import BookCaseInfo from "../book_case/BookCaseInfo";
import WhitePhoneIndex from "../white_phone";
import { EBookIndex } from "../ebook/EBookIndex";
import { EBookPageIndex } from "../ebook/EBookPageIndex";

interface P {
  manager: Manager | undefined | null
}
class HomeIndex extends React.Component<P & RouteComponentProps , {}>{

  render() {
    const { manager } = this.props;

    return (
      <div>
        <Layout>
          <Layout.Header>
            {/*<img src={'/img/ico_topbar_jumanyi.svg'} alt={'logo'}/>*/}
            多宝乐园
            <div className="action">
              <span>{manager && manager.username}</span>
              <Button
                type={'link'}
                className={'quit'}
                style={{color: 'white'}}
                onClick={this._logout}
              >
                退出
              </Button>
            </div>
          </Layout.Header>
          <Layout style={{minHeight: '100vh'}}>
            <Layout.Sider>
              <Menu mode="inline" defaultSelectedKeys={['1']}>
                <Menu.Item key="1" className={'home'}>
                  <Link to={'/dashboard'}>
                      <span className="icon"/>首页
                  </Link>
                </Menu.Item>
                <Menu.SubMenu title={<div className="control"><span className="icon"/>个人中心</div>}>
                  {/*<Menu.Item key="2"><Link to={'/folder/index'}>我的文档</Link></Menu.Item>*/}
                  <Menu.Item key="3"><Link to={'/book-case/index'}>我的书橱</Link></Menu.Item>
                </Menu.SubMenu>


                <Menu.SubMenu title={<div className="line"><span className="icon"/>访问控制</div>}>
                  <Menu.Item key="4"><Link to={'/white-list'}>白名单</Link></Menu.Item>
                </Menu.SubMenu>
                <Menu.SubMenu title={<div className="line"><BookOutlined />电子书管理</div>}>
                  <Menu.Item key="5"><Link to={'/e-book/index'}>电子书</Link></Menu.Item>
                </Menu.SubMenu>

              </Menu>
            </Layout.Sider>
            <Layout.Content style={{ margin: '24px 16px 0' }}>
              <Switch>
                <Route component={ EBookPageIndex } path={'/e-book/:id/pages'}/>
                <Route component={ EBookIndex } path={'/e-book/index'}/>
                <Route component={ BookCaseIndex } path={'/book-case/index'} />
                <Route component={ BookCaseInfo } path={'/book-case/:id'}/>

                <Route component={ FolderIndex } path={'/folder/index'} />
                <Route component={ BookNew } path={'/book/new'} />
                <Route component={ BookNew } path={'/book/:id'} />

                <Route component={ WhitePhoneIndex } path={'/white-list'} />

                <Route component={ Dashboard } path={'/dashboard'}/>

              </Switch>
            </Layout.Content>
          </Layout>
          <Layout.Footer/>
        </Layout>
      </div>
    );
  }

  private _logout  = () => {
    SecurityRepository.$.logout().then(() => {
      this.props.history.push('/sign-in');
    }).catch(() => this.props.history.push('/sign-in'));
  }
}

export default connect(
  (app: AppState) => ({manager: app.manager.manager}),
  (dispatch: Dispatch) => bindActionCreators({
  }, dispatch)
)(HomeIndex);
