import { createContext } from "react";

export const PageSizeContext = createContext(20);

export function imgUrl(thumb: string) {
 
  return thumb ? [
    '/fs',
    thumb.substr(0, 2),
    thumb.substr(2, 2),
    thumb
  ].join('/') : '';
}
