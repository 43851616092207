import { FolderForm } from "../form/FolderForm";

export class Folder extends FolderForm{

  static fromJSON(data: FolderJSON) {

    return new Folder(data.id, data.name, data.description);
  }

  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly description?: string
  ) {
    super(name, description);
  }

}

export interface FolderJSON {
  readonly id: number;
  readonly name: string;
  readonly description?: string;
}
