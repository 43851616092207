import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Button, Form, Input, message, Spin, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import './index.css'
import TextArea from "antd/lib/input/TextArea";
import { BookForm } from "../../form/BookForm";
import { BookRepository } from "../../repository/BookRepository";
import { useHistory, useLocation, useParams } from "react-router";
import { BookCaseRepository } from "../../repository/BookCaseRepository";

function BookNew() {

  const repo = useContext(BookRepository.Context);
  const caseRepo = useContext(BookCaseRepository.Context);
  const history = useHistory();
  const [form, setForm] = useState(new BookForm());
  const [loading, setLoading] = useState(false);
  const {search} = useLocation();
  const params = new URLSearchParams(search);
  const caseId = params.get('case');
  const {id} = useParams();
  const props = {
    name: 'file[]',
    action: '/fs/',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info: any) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${ info.file.name } file uploaded successfully`);
        setForm(form.amend('attachment', info.file.response.filenames[0]));
      } else if (info.file.status === 'error') {
        message.error(`${ info.file.name } file upload failed.`);
      }
    },

  };

  useEffect(function () {
    if (id) {
      repo.find(+id).then(c => setForm(c))
    } else if (caseId) {
      caseRepo
        .find(+caseId)
        .then(c => {
          setForm(form.amend('cupboard', c));
        })
    }

  }, [caseRepo, caseId, id, repo]);

  const save = async () => {
    setLoading(true);
    await repo.persist(form, id ? +id : undefined);
    history.push(`/book-case/${ caseId }`);
    setLoading(false);
  };

  return (
    <div className={ 'main-content' }>
      <Spin spinning={ loading }>
        <Form
          layout={ 'vertical' }
          name="basic"
          className={ 'book-form' }
        >
          <Form.Item
            label="标题"
            required>
            <div className={ 'upload-con' }>
              <div className={ 'upload-bar' }>
                <Upload { ...props }>
                  <Button icon={ <UploadOutlined/> }>选择上传的PDF</Button>
                </Upload>
              </div>
            </div>
          </Form.Item>
          <Form.Item
            label="标题"
            required

          >
            <Input
              value={ form.name }
              onChange={ (e) => setForm(form.amend('name', e.currentTarget.value)) }
            />
          </Form.Item>
          <Form.Item
            label="顺序"
            required

          >
            <Input
              value={ form.sequence }
              onChange={ (e) => setForm(form.amend('sequence', +e.currentTarget.value)) }
            />
          </Form.Item>
          <Form.Item
            label="描述信息"
            required
          >
            <TextArea
              value={ form.description }
              onChange={ (e) => setForm(form.amend('description', e.currentTarget.value)) }
            />
          </Form.Item>

          <Button type={ 'primary' } onClick={ () => save() }>保存</Button>
        </Form>
      </Spin>
    </div>
  )
    ;
}

export default BookNew
