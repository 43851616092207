import { Book, BookJSON } from "./Book";
import { BookCaseForm } from "../form/BookCaseForm";

export class BookCase extends BookCaseForm{

  static fromJSON(data: BookCaseJSON) {

    return new BookCase(
      data.id,
      data.name,
      data.description,
      data.books ? data.books.map(Book.fromJSON) : [],
      data.thumb,
      data.sequence
    );
  }

  constructor(
    public readonly id: number,
    public readonly name: string,
    public readonly description: string,
    public readonly books: Book[],
    public readonly thumb?: string,
    public readonly sequence?: number
  ) {
    super(name, description, thumb);
  }
}

export interface BookCaseJSON {
  readonly id: number;
  readonly name: string;
  readonly description: string;
  readonly books?: BookJSON[];
  readonly thumb?: string;
  readonly sequence?: number;
}
