import * as React from 'react';
import { Button } from "antd";
import { Link } from 'react-router-dom';

import './index.css';
import FolderModal from "./FolderModal";
import { useCallback, useContext, useEffect, useState } from "react";
import { FolderForm } from "../../form/FolderForm";
import { FolderRepository } from "../../repository/FolderRepository";
import { PageSizeContext } from "../../model";
import { Fragment } from "../../model/Fragment";
import { Folder } from "../../model/Folder";

function FolderIndex() {
  const repo = useContext(FolderRepository.Context);
  const pageSize = useContext(PageSizeContext);

  // const [ page, setPage ] = useState(1);
  const page = 1;
  const [ visible, setVisible ] = useState(false);
  const [ f, setF ] = useState<Fragment<Folder> | undefined>(undefined);

  const save = async (form: FolderForm) => {
    await repo.persist(form);
    setVisible(false);
  };

  const find = useCallback(function () {
    repo
      .findAll(page, pageSize)
      .then(f => {
        setF(f);
      })
  }, [page, pageSize, repo]);

  useEffect(()=> {
    find();
  } , [find]);

  return (
    <div className="folder-index">
      <div className={'title'}>
        我的文档
      </div>
      <div className={'content'}>
        <div className={'tool'}>
          <Link className={'link'} type={'primary'} to={'/book/new'}>上传PDF</Link>
          <Button className={'link'} type={'primary'} onClick={() => setVisible(true)}>新建文件夹</Button>
          <Button className={'link'} type={'primary'}>清空</Button>
        </div>

        <div className="list">
          {f && f.data.map(p => {
            return (
              <div className="folder">
                <div className={'icon'}>
                  <img src="/img/folder.svg" alt={''}/>
                </div>
                <div className="c">
                  <div className={'name'}>{p.name}</div>
                  <div className={'description'}>{p.description}</div>
                </div>
              </div>
            );
          })}
        </div>

      </div>
      <FolderModal
        visible={visible}
        onCancel={() => { setVisible(false)}}
        onConfirm={(form) => { form && save(form)}}
      />
    </div>
  );
}

export default FolderIndex;
