import React, { useState } from 'react';
import { Modal, Form, Input } from "antd";

function WhiteModal(props: {
  visible: boolean;
  onCancel:() => void;
  onConfirm: (mobile: string) => void;
}) {

  const { visible, onCancel, onConfirm } = props;
  const [ mobile, setMobile ] = useState<string>('');

  return (
    <Modal
      title={'新建书橱'}
      visible={visible}
      okText={'确认'}
      cancelText={'取消'}
      onCancel={() => onCancel()}
      onOk={() => { onConfirm(mobile)}}
    >
      <Form
        layout={'vertical'}
        name="basic"
      >
        <Form.Item
          label="电话号码"
          required
        >
          <Input
            value={mobile}
            onChange={(e) => setMobile(e.currentTarget.value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default WhiteModal;
