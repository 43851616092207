import React, { useState } from 'react';

import { Form, Input, Modal } from "antd";
import { EBookForm } from "../../form/EBookForm";

export default function EBookModal(props: {
  onOk: (form: EBookForm) => void;
  onCancel: () => void;
  form: EBookForm
}) {
  const { form, onOk, onCancel}  = props;
  const [ f, setF ] = useState<EBookForm>(form);

  return (
    <Modal
      title={'新建电子书'}
      visible={true}
      okText={'确定'}
      cancelText={'取消'}
      onCancel={() => onCancel()}
      onOk={() => onOk(f)}
    >
      <Form
        layout={'vertical'}
        name="basic"
      >
        <Form.Item
          label="名称"
          required
        >
          <Input onChange={(e) => setF(form.amend('name', e.target.value))} value={f.name}/>
        </Form.Item>
      </Form>
    </Modal>
  );
}
