import React, { useState } from 'react';
import { Form, Input, Modal } from "antd";
import { PageCatalogForm } from "../../form/PageForm";

export function CatalogModal (props: {
  form: PageCatalogForm,
  onOk: (form: PageCatalogForm) => void,
  onCancel: () => void
}) {
  const { form, onOk, onCancel } = props;

  const [ f, setF ] = useState<PageCatalogForm>(form);
  return (
    <Modal
      title={'添加目录'}
      okText={'确定'}
      cancelText={'取消'}
      onCancel={() => onCancel()}
      onOk={() => onOk(f)}
      visible={true}
    >
      <Form
        layout={ 'vertical' }
        name="basic"
      >
        <Form.Item
          label="名称"
          required
        >
          <Input onChange={ (e) => setF(f.amend('name', e.target.value)) } value={ f.name }/>
        </Form.Item>
        <Form.Item
          label="页码"
          required
        >
          <Input onChange={ (e) => setF(f.amend('number', +e.target.value)) } value={ f.number }/>
        </Form.Item>
      </Form>
    </Modal>
  );
}
