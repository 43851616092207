import axios, { AxiosInstance } from "axios";
import { createContext } from "react";
import { BookForm } from "../form/BookForm";
import { Book } from "../model/Book";

export class BookRepository {

  static $ = new BookRepository(axios);
  static Context = createContext(BookRepository.$);

  constructor(private a: AxiosInstance) {

  }

  async persist(form: BookForm, id?: number) {
    const { data } = id ? await this.a.put(`/books/${id}`, {form: form.flush()}) : await this.a.post('/books', {form: form.flush()});

    return Book.fromJSON(data);
  }

  async find(id: number) {
    const { data } = await this.a.get(`/books/${id}`);

    return Book.fromJSON(data);
  }

  async remove(book: Book) {
    await this.a.delete(`/books/${book.id}`);
  }

}
