import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Modal, Spin, Table } from 'antd';
import { AppstoreAddOutlined, EditOutlined, DeleteOutlined, BarsOutlined }  from '@ant-design/icons';

import './index.css';
import EBookRepository from "../../repository/EBookRepository";
import { Fragment } from "../../model/Fragment";
import { EBook } from "../../model/EBook";
import { usePager } from "../widget/Paginator";
import { EBookForm } from "../../form/EBookForm";
import EBookModal from "../widget/EBookModal";
import { useHistory } from "react-router";

export function EBookIndex () {

  const repo = useContext(EBookRepository.Context);
  const history = useHistory();

  const [ page, size ] = usePager();

  const [ fragment, setFragment ] = useState<Fragment<EBook> | undefined>(undefined);
  const [ form, setForm ] = useState<EBookForm | undefined>(undefined);
  const [ spinning, setSpinning ] = useState(false);

  const find = useCallback(async () => {
    setSpinning(true);
    const f = await repo.findByPage(page, size);
    setFragment(f);
    setSpinning(false);
  }, [repo, page, size]);

  const persist = async (form: EBookForm) => {
    setSpinning(true);
    await repo.persist(form, form.id);
    find();
    setSpinning(false);
  };

  const remove = async (id: number, name: string) => {
    Modal.confirm({
      title: '确认删除',
      content: '确认删除'+name,
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        repo.remove(id).then(_ => find());
      }
    });
  };



  useEffect(() => {
    find();
  }, [find]);

  return (<div className={'main-content'}>
    <div className={'padding'}>
      <h3>电子书管理</h3>
      <div className={'tool-bar'}>
        <Button type={'link'} onClick={() => { setForm(new EBookForm())}}><AppstoreAddOutlined />新建</Button>
      </div>
      <Spin spinning={spinning}>
        <Table
          columns={[
            {
              title: '名称',
              dataIndex: 'name',
              key: 'name',
            },
            {
              title: '创建时间',
              dataIndex: 'createdAt',
              key: 'createdAt',
            },
            {
              title: '操作',
              dataIndex: 'actions',
              key: 'actions',
            },
          ]}
          dataSource={fragment?.data.map(f => {
            return {
              name: f.name,
              createdAt: f.createdAt.format('YYYY-MM-DD hh:mm:ss'),
              actions: <>
                <Button type={'link'} onClick={() => history.push(`/e-book/${f.id}/pages`)}><BarsOutlined />内容管理</Button>
                <Button type={'link'} onClick={() => setForm(f)}><EditOutlined />编辑</Button>
                <Button type={'link'} onClick={() => remove(f.id, f.name)}><DeleteOutlined />删除</Button>
              </>
            }
          })}
        />
      </Spin>
    </div>
    {form && <EBookModal
        onOk={(f) => { setForm(undefined);persist(f)}}
        onCancel={() => setForm(undefined)}
        form={form}
    />}
  </div>);
}
