import * as React from 'react';
import { Menu } from 'semantic-ui-react';

import Page from './Page';

interface IPaginationProps {
  route: (n: number) => string;
  route0?: string;
  page: number;
  count: number;
  margin: number;
  onPage?: (p: number) => void;
}

class Pagination extends React.Component<IPaginationProps> {

  public render() {
    if (this.props.count < 2) {
      return null;
    }

    return (
      <Menu pagination>
        {Array(this.props.count).fill(undefined).map((_, i) => this.renderPage(1 + i))}
      </Menu>
    );
  }

  private renderPage = (p: number) => {
    const { route, route0, page, count, margin, onPage } = this.props;

    if ((1 <= p && p < 1 + margin)
        || (page - margin <= p && p <= page + margin)
        || (count - margin < p && p <= count)) {
      return (
        <Page n={p} to={(p < 2 && route0) || route(p)} active={p === page} onPage={onPage} key={`#${p}`} />
      );
    }
    if (p === 1 + margin || p === count - margin) {
      return (
        <Menu.Item content="..." key={`#${p}`} />
      )
    }

    return null;
  }

}

export default Pagination;
