import axios, { AxiosInstance } from "axios";
import { createContext } from "react";
import { Fragment, FragmentJSON } from "../model/Fragment";
import { EBook, EBookJSON } from "../model/EBook";
import { EBookForm } from "../form/EBookForm";
import { Page } from "../model/Page";
import { PageAudioForm, PageCatalogForm } from "../form/PageForm";

export default class EBookRepository {

  static $ = new EBookRepository(axios);
  static Context = createContext(EBookRepository.$);

  constructor(private a: AxiosInstance) {

  }

  async findByPage(page: number = 1, size: number = 15) {
    const { data } = await this.a.get<FragmentJSON<EBookJSON>>('/e-book');

    return Fragment.fromJSON(data, EBook.fromJSON);
  }

  async find(id: number) {
    const { data } = await this.a.get(`/e-book/${id}`);
    console.log(data);
    return EBook.fromJSON(data);

  }

  async persist(form: EBookForm, id?: number) {
    const { data } = id ? await this.a.put(`/e-book/${id}`, { form: form.flush()}) : await this.a.post('/e-book', {form: form.flush()});

    return EBook.fromJSON(data);
  }

  async remove(id: number) {
    await this.a.delete(`/e-book/${id}`);
  }

  async findPage(id: number, page: number) {
    const { data } = await this.a.get(`/e-book/${id}/page/${page}`);

    return Page.fromJSON(data);
  }

  async findPages(id: number, page: number = 1, size: number = 15, parent?: number)
  {
    const { data } = await this.a.get(`/e-book/${id}/pages`, {
      params: { parent }
    });

    return Fragment.fromJSON(data, Page.fromJSON);
  }

  async persistPage(id: number, form: PageAudioForm | PageCatalogForm)
  {
    const { data } = form.id ? await this.a.put(`/e-book/${id}/page/${form.id}`, {page: form.flush()}) : await this.a.post(`/e-book/${id}/page/${form.type}`, {page: form.flush()});

    return Page.fromJSON(data);
  }


  async removePage(id: number, page: number)
  {
    await this.a.delete(`/e-book/${id}/page/${page}`);
  }

}
