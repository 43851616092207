import { combineReducers } from 'redux';

import manager, { ManagerState } from './manager';

const reducers = {
  manager
};

export default combineReducers(reducers);

export interface AppState {
  manager: ManagerState
}
