import React, { createContext, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import URI from 'urijs';

import Pagination from './Pagination';

interface PagerOptions {
  readonly defaultPageSize: number;
  readonly pageKey: string;
  readonly sizeKey: string;
}

export const PagerOptionsContext = createContext<PagerOptions>({
  defaultPageSize: 10,
  pageKey: 'p',
  sizeKey: 'n',
});

export function useQuery<Params extends { [K in keyof Params]?: string } = {}>(): { [p in keyof Params]?: string } {
  const { search } = useLocation();

  return URI.parseQuery(search) as Params;
}

export function usePager(): [ number, number, (page: number, size?: number) => string ] {
  const { defaultPageSize, pageKey, sizeKey } = useContext(PagerOptionsContext);
  const { pathname, search } = useLocation();
  const query: any = URI.parseQuery(search);
  const p: number = +query[pageKey] || 1;
  const n: number = +query[sizeKey] || defaultPageSize;

  return [
    p,
    n,
    (page: number, size: number = n) => URI.build({
      path: pathname,
      query: URI.buildQuery(Object.assign(query, {
        [pageKey]: page !== 1 ? page : undefined,
        [sizeKey]: size !== defaultPageSize ? size : undefined,
      })),
    }),
  ];
}

interface P {
  total: number;
  margin?: number;
}

function Paginator({ total, margin }: P) {
  const [ page, size, route ] = usePager();

  return (
    <Pagination route={route} page={page} count={Math.ceil(total / size)} margin={margin || 2} />
  );
}

export default Paginator;
