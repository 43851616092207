import React from 'react';

import { Modal } from "antd";

export function QrCodeModal (props: {
  value?: string;
  onCancel: () => void;
  onOk: () => void;
}) {

  const { value, onOk, onCancel } = props;
  return (
    <Modal
      visible={true}
      title={'二维码'}
      onOk={() => {onOk()}}
      onCancel={() => {onCancel()}}
    >
      <img src={value} alt={''}/>
    </Modal>
  );
}
