import React from 'react';

import './index.css';
import { Route, Switch } from "react-router";
import { EBookIndex } from "./ebook/EBookIndex";
import { EBookList } from "./ebook/EBookList";
export function MobileIndex () {

  return (
    <>
      <Switch>
        <Route path={'/m/e-book/:id/pages/:page'} component={EBookIndex}/>
        <Route path={'/m/e-book/:id/:pid'} component={EBookList}/>
      </Switch>
    </>
  );
}
