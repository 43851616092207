import * as React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Form, Input, Button, message, Spin } from 'antd';

import './index.css';
import { SyntheticEvent } from 'react';
import { AppState } from '../../redux/rootReducer';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { SecurityRepository } from '../../repository/SecurityRepository';
import { login } from '../../redux/manager/index';
import { Manager } from '../../model/Manager';

interface P {
  login(manager: Manager): void ;
}
interface S {
  loading: boolean;
  username: string;
  password: string;
}
class SignIn extends React.Component<P & RouteComponentProps, S>{

  state: S = {loading: false, username: '', password: ''};
  render()
  {
    const { username, password, loading } = this.state;
    return (
      <div className="body">
        <div className={'sign-in'}>
          {/*<img src={"/img/driver_ico.svg"} alt={''}/>*/}
          <h3>多宝乐园图书展示平台</h3>
          <Spin spinning={loading}>
            <Form onFinish={this._submit}  className={'sign-form'}>
              <Form.Item label="用户名">
                <Input
                  placeholder={''}
                  value={username}
                  onChange={(e) => this.setState({
                    username: e.currentTarget.value
                  })}
                />
              </Form.Item>
              <Form.Item label="密码">
                <Input
                  placeholder={''}
                  type={'password'}
                  value={password}
                  onChange={(e) => this.setState({
                    password: e.currentTarget.value
                  })}
                />
              </Form.Item>
              <Form.Item style={{paddingTop: '10px', marginBottom: '0'}}>
                <Button type="primary" className={'btn'} htmlType={'submit'}>登录</Button>
              </Form.Item>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }

  private _submit = (e: SyntheticEvent) => {
    const { username, password } = this.state;
    if (!username) {
      message.error('手机号不能为空');
      return;
    }
    if (!password) {
      message.error('密码不能为空');
      return;
    }
    this.setState({
      loading: true
    });
    SecurityRepository.$.login(username, password).then((manager) =>
      {
        this.props.login(manager);
        this.props.history.push('/dashboard');
        this.setState({
          loading: false
        });
      }
    ).catch((e) => {
      console.error(e);
      message.error('密码错误');
      this.setState({
        loading: false
      });
    });
  }
}

const mapStateToProps = (app: AppState) => ({});
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  login: login
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
