import { BookCase } from "../model/BookCase";

export class BookForm {

  constructor(
    public readonly name: string = '',
    public readonly description?: string,
    public readonly attachment?: string,
    public readonly cupboard?: BookCase,
    public readonly audio?: string,
    public readonly sequence?: number
  ) {

  }

  amend<K extends keyof BookForm>(key: K, value: BookForm[K]) {

    const { name, description, attachment, cupboard, audio, sequence } = Object.assign({}, this, {[key]: value});

    return new BookForm(name, description, attachment, cupboard, audio, sequence);
  }

  flush() {
    return ({
      name: this.name,
      description: this.description,
      attachment: this.attachment,
      cupboard: this.cupboard?.id,
      audio: this.audio,
      sequence: this.sequence
    });
  }
}
