import { Book } from "../model/Book";
import { PageAudio, PageCatalog } from "../model/Page";
import { EBook } from "../model/EBook";

export class PageForm {

  constructor(
    public readonly number?: number,
    public readonly book?: Book,
    public readonly id?: number
  ) {

  }

}

export class PageAudioForm {

  static fromPageAudio(data: PageAudio) {

    return new PageAudioForm(
      data.title,
      data.cover,
      data.audio,
      data.number,
      data.book,
      data.id,
      data.refLink
    );
  }

  constructor (
    public readonly title?: string,
    public readonly cover?: string,
    public readonly audio?: string,
    public readonly number?: number,
    public readonly book?: EBook,
    public readonly id?: number,
    public readonly refLink?: string,
    public readonly parent?: number
  ) {

  }

  amend<K extends keyof PageAudioForm>(key: K, value: PageAudioForm[K]) {

    const { title, cover, audio, number, id, book, refLink, parent } = Object.assign({}, this, {[key]: value});

    return new PageAudioForm(title, cover, audio, number, book, id, refLink, parent);
  }

  flush() {
    return ({
      number: this.number,
      title: this.title,
      book: this.book?.id,
      audio: this.audio,
      cover: this.cover,
      refLink: this.refLink,
      parent: this.parent
    });
  }

  get type() {
    return 'audio';
  }
}

export class PageCatalogForm {

  static fromPageCatalog(data: PageCatalog) {
    return new PageCatalogForm(
      data.name,
      undefined,
      data.number,
      data.id
    );
  }

  constructor(
    public readonly name?: string,
    public readonly book?: EBook,
    public readonly number?: number,
    public readonly id?: number,
    public readonly parent?: number,
  ) {

  }

  amend<K extends keyof PageCatalogForm>(key: K, value: PageCatalogForm[K]) {

    const { name, book, number, id, parent } = Object.assign({}, this, {[key]: value});
    console.log(name);
    console.log(number);

    return new PageCatalogForm(name, book, number, id, parent);
  }

  flush() {
    return ({
      name: this.name,
      book: this.book?.id,
      number: this.number,
      parent: this.parent
    });
  };

  get type() {
    return 'catalog';
  }

}
