import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useCallback, useContext, useEffect, useState } from "react";
import { BookCaseRepository } from "../../repository/BookCaseRepository";
import { BookCase } from "../../model/BookCase";
import { Spin, Button, Modal } from "antd";
import { Book } from "../../model/Book";
import { BookRepository } from "../../repository/BookRepository";

function BookCaseInfo() {
  const repo = useContext(BookCaseRepository.Context);
  const bookRepo = useContext(BookRepository.Context);

  const [ bookCase, setBookCase ] = useState<BookCase | undefined>(undefined);
  const [ loading, setLoading ] = useState<boolean>(false);
  const { id } = useParams();
  const [ p, setP ] = useState(false);

  const find = useCallback(function () {
    if (id) {
      setLoading(true);
      repo.find(+id).then(c => {
        setBookCase(c);
        setLoading(false);
      });
    }
  }, [id, repo]);

  const remove = (book: Book) => {
    setLoading(true);
    bookRepo.remove(book).then(() => {
      setLoading(false);
      find();
    })
  };

  const preview = () => {
    setP(true);
  };

  useEffect(() => {
    find();
  }, [find]);
  return (
    <div className="folder-index">
     <Spin spinning={ loading }>
       <div className={'title'}>
         {bookCase?.name}
       </div>
       <div className={'content'}>
         <div className={'tool'}>
           <Link className={'link'} type={'primary'} to={`/book/new?case=${bookCase?.id}`}>上传PDF</Link>
           <Button type={'link'} onClick={() => preview()}>预览</Button>
         </div>

         <div className="list">
           {bookCase && bookCase.books.map(p => {
             return (
               <div className={'book'}>
                 <div className="folder">
                   <div className={'icon'}>
                     <img src="/img/folder.svg" alt={''}/>
                   </div>
                   <div className="c">
                     <div className={'name'}>{p.name}</div>
                     <div className={'description'}>{p.description}</div>
                   </div>
                   <div className={'actions'} style={{textAlign: 'right', flex: 1}}>
                     <Button type={'link'}  onClick={() => remove(p) }>删除</Button>
                     <Link to={`/book/${p.id}?case=${bookCase?.id}`}>修改</Link>
                   </div>
                 </div>
                 <div className={'more'}>
                   <div className={'create'}>创建时间：{ p.createdAt?.format('YYYY-MM-DD HH:mm:ss')}</div>
                   <div className={'status'}>{!p.resolvedAt ? '系统处理中' : `处理时间:${p.resolvedAt.format('YYYY-MM-DD HH:mm:ss')}`}</div>
                 </div>
               </div>
             );
           })}
         </div>
       </div>
       {p && <Modal
        title={'小程序预览'}
        visible={true}
        onCancel={() => setP(false)}
        onOk={() => setP(false)}
       >
        <img src={`/wheel/api/v1/books-cases/${id}/preview`} alt={''}/>
       </Modal>}
     </Spin>
    </div>
  );
}

export default BookCaseInfo;
