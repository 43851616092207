import * as React from 'react';
import { connect } from 'react-redux';
import { AppState } from './redux/rootReducer';
import { bindActionCreators, Dispatch } from 'redux';
import { Redirect } from 'react-router';
import { Manager } from './model/Manager';

interface P {
    manager: Manager | undefined | null
}
class Firewall extends  React.Component<P & {children: any}, {}>{

    render() {
        if (!this.props.manager) {
            return <Redirect to={'/sign-in'}/>
        }
        return this.props.children;
    }
}

const mapStateToProps = (app: AppState) => ({
    manager: app.manager.manager
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Firewall);
