import * as React from 'react';
import { Modal, Form, Input, message, Upload, Button } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useState } from "react";
import { BookCaseForm } from "../../form/BookCaseForm";
import { UploadOutlined } from '@ant-design/icons';

function BookCaseModal(props: {
  visible: boolean;
  form?: BookCaseForm,
  onCancel: () => void,
  onConfirm: (form?: BookCaseForm) => void
}) {

  const { visible, form, onCancel, onConfirm } = props;

  const uploadProps = {
    name: 'file[]',
    action: '/fs/',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info: any) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
        setF(f.amend('thumb', info.file.response.filenames[0]));
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },

  };
  console.log(form);
  const [f, setF] = useState(form ? form : new BookCaseForm(''));
  return (
    <Modal
      title={'新建书橱'}
      visible={visible}
      okText={'确认'}
      cancelText={'取消'}
      onCancel={() => onCancel()}
      onOk={() => { onConfirm(f); console.log(f)}}
    >
      <Form
        layout={'vertical'}
        name="basic"
      >
        <Form.Item
          label="书橱名称"
          required
        >
          <Input
            value={f?.name}
            onChange={(e) => setF(f?.amend('name', e.currentTarget.value))}
          />
        </Form.Item>
        <Form.Item
          label="书橱描述"
        >
          <TextArea
            value={f?.description}
            onChange={(e) => setF(f?.amend('description', e.currentTarget.value))}
          />
        </Form.Item>
        <Form.Item
          label="书橱封面"
        >
          <Upload {...uploadProps}>
            <Button icon={<UploadOutlined />}>选择上传的图片</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="排序"
          required
        >
          <Input
            value={f?.sequence}
            onChange={(e) => setF(f?.amend('sequence', +e.currentTarget.value))}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default BookCaseModal;
