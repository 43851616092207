import * as React from 'react';
import { AppState } from '../redux/rootReducer';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { BrowserRouter } from 'react-router-dom';
import { Route, Switch} from 'react-router';
import SignIn from './sign/SignIn';
import HomeIndex from './home/HomeIndex';
import { findManager } from '../redux/manager/index';
import { Manager } from '../model/Manager';
import Firewall from '../Firewall';
import * as _ from 'lodash';
import { MobileIndex } from "./mobile/MobileIndex";

interface P {
  find(): void;
  manager: Manager | undefined | null;
}
class Entry extends React.Component<P, {}> {

  componentDidMount() {
    this.props.find();
  }
  render() {
    if (_.isUndefined(this.props.manager)) {
      return null;
    }
    return (
      <BrowserRouter>
        <Switch>
          <Route path={'/m'} component={ MobileIndex }/>
          <Route path={'/sign-in'} component={ SignIn }/>
          <Firewall>
              <Route path={'/'} component={ HomeIndex }/>
          </Firewall>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default connect(
  (app: AppState) => ({manager: app.manager.manager}),
  (dispatch: Dispatch) => bindActionCreators({
    find: findManager
  }, dispatch)
)(Entry);
