import axios, { AxiosInstance } from "axios";
import { createContext } from "react";
import { WhitePhone } from "../model/WhitePhone";

export class WhitePhoneRepository {

  static $ = new WhitePhoneRepository(axios);
  static Context = createContext(WhitePhoneRepository.$);

  constructor(private a: AxiosInstance) {

  }

  async findAll() {
    const { data } = await this.a.get('/white');

    return data.map(WhitePhone.fromJSON);
  }

  async persist(mobile: string) {
    const { data } = await this.a.post('/white', {mobile: mobile});

    return WhitePhone.fromJSON(data);
  }

  async remove(id: number) {
     await this.a.delete(`/white/${id}`);
  }

}
