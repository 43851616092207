import axios, { AxiosInstance } from "axios";
import { createContext } from "react";
import { Fragment, FragmentJSON } from "../model/Fragment";
import { BookCaseForm } from "../form/BookCaseForm";
import { BookCase, BookCaseJSON } from "../model/BookCase";

export class BookCaseRepository {

  static $ = new BookCaseRepository(axios);
  static Context = createContext(BookCaseRepository.$);

  constructor(private a: AxiosInstance) {

  }

  async findAll(page: number = 1, size: number = 15) {
    const { data } = await this.a.get<FragmentJSON<BookCaseJSON>>('/books-cases/list', {params: { page, size }});

    return Fragment.fromJSON(data, BookCase.fromJSON);
  }

  async find(id: number) {
    const { data } = await this.a.get(`/books-cases/${id}`);

    return BookCase.fromJSON(data);
  }

  async persist(form: BookCaseForm, id?: number) {
    const { data } = id ? await this.a.put(`/books-cases/${id}`, { form: form.flush()}) : await this.a.post(`/books-cases`, { form: form.flush()});

    return BookCase.fromJSON(data);
  }

  async remove(id: number) {
    await this.a.delete(`/books-cases/${id}`);
  }


  async preview(id: number) {
    this.a.get(`/books-cases/${id}/preview`);
  }
}
