import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import './index.css';
import {Button } from "antd";
import EBookRepository from "../../../repository/EBookRepository";
import { EBook } from "../../../model/EBook";
import { useParams } from "react-router";
import { PageAudio, PageCatalog } from "../../../model/Page";
import { imgUrl } from "../../../model";
import { useHistory } from "react-router";

export function EBookList () {
  const repo = useContext(EBookRepository.Context);
  const [ book, setBook ] = useState<EBook | undefined>(undefined);
  // const [ detail, setPages ] = useState<PageAudio | PageCatalog | undefined>(undefined);
  // const [ pageAudio, setPage ] = useState<PageAudio | undefined>(undefined);
  const { id,pid } = useParams();
  const history = useHistory();
 console.log(id,pid)
  
 
   
  const gotodetail=(index:number)=>{
    var book:Array<Array<number>>= [];
    if(id=='english1'){
      //英语1级
       book = [
        [13,146],
        [14,161]
      ];
    }
   
   
 
   
    history.push('/m/e-book/'+book[index][0]+'/pages/'+book[index][1]);
 
 
  };
 
  return (
  
    <div className={'book-con'} style={{background:`url("/m_imgs2/m_bg1.png") no-repeat`,backgroundSize:`cover`}}>
         <Helmet title={book?.name}/>
      <div className={'imglist'}>
       {Array.from({ length: 5 }, (v, i) => {
        if(i==4){
        return <Button key={i} type={'link'} onClick={() => gotodetail(i)} className={'imglistitem'} >
             <div className={'imgbg1'} style={{ background :  pid == i ?  `url(/m_imgs2/book_bg1_s.png) 50% 0% no-repeat` : `url(/m_imgs2/book_bg1.png) 50% 0% no-repeat`,backgroundSize:'80% 100%'}}>
              <img  src={'/m_imgs2/english1_'+i+'.png'} />
            </div>
         </Button>
        }else{
        return  <Button key={i} type={'link'} onClick={() => gotodetail(i)} className={'imglistitem'} >
             <div className={'imgbg'} style={{ background :  pid == i ?  `url(/m_imgs2/book_bg_s.png) no-repeat` : `url(/m_imgs2/book_bg.png) no-repeat`,backgroundSize:'100% 100%'}}>
              <img  src={'/m_imgs2/english1_'+i+'.png'} />
            </div>
         </Button>
        }
      })}
     
      </div>
      

    </div>
  );
}
