
export class BookCaseForm  {

  constructor(
    public readonly name: string = '',
    public readonly description?: string,
    public readonly thumb?: string,
    public readonly sequence?: number
  ) {

  }

  amend<K extends keyof BookCaseForm>(key: K, value: BookCaseForm[K]) {

    const { name, description, thumb, sequence } = Object.assign({}, this, {[key]: value});

    return new BookCaseForm(name, description, thumb, sequence);
  }

  flush() {
    return ({
      name: this.name,
      description: this.description,
      thumb: this.thumb,
      sequence: this.sequence
    });
  }

}
