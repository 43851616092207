import { Manager } from '../model/Manager';
import axios, { AxiosInstance } from 'axios';

export class SecurityRepository {

  static $ = new SecurityRepository(axios);
  constructor(private a: AxiosInstance) {

  }
  async login(username: string, password: string):Promise<Manager> {
    const { data } = await this.a.post('/login', {
      _username: username,
      _password: password
    });
    return Manager.fromJSON(data);
  }

  async get():Promise<Manager> {
    const { data } = await this.a.get('/manager');
    return Manager.fromJSON(data);
  }

  async logout(): Promise<void> {
    await this.a.post('/logout');
  }
}