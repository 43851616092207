import * as React from 'react';
import { Provider } from 'react-redux';
import axios from 'axios';
import store from './redux/store';
import moment from 'moment'
import Entry from './components/Entry';

import './App.css';
import LocaleProvider from "antd/lib/locale-provider";
import zh_CN from 'antd/lib/locale-provider/zh_CN';

axios.defaults.baseURL = '/wheel/api/v1';
moment.locale('zh_cn');

class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <LocaleProvider locale={zh_CN}>
          <Entry/>
        </LocaleProvider>
      </Provider>
    );
  }
}

export default App;
