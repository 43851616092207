import * as React from 'react';
import { Spin } from 'antd';

import './dashboard.css'

function Dashboard() {

  // useEffect(() => {
  //   const from = moment().subtract(1, 'days').startOf('day');
  //   const to = moment().startOf('day');
  //   repo.findSts().then(d => setDash(d));
  //   memberRepo.findSummary(2, from, to).then(s => setTeacherSummary(s));
  //   // findTeacherSummary();
  // }, [repo, memberRepo, kidRepo]);
  return (
    <Spin spinning={false}>
      <div className="dash content">
        <div className={'line'}>
          {/*<div><Typography.Title level={3}>教师端</Typography.Title></div>*/}
          {/*<div className={'data'}>*/}
          {/*  <Row gutter={24}>*/}
          {/*    <Col span={8}>*/}
          {/*      <Statistic title="总人数" value={dash?.teacher_quantity} />*/}
          {/*    </Col>*/}
          {/*    <Col span={8}>*/}
          {/*      <Statistic title="昨日新增" value={teacherSummary?.increase} />*/}
          {/*    </Col>*/}
          {/*    <Col span={8}>*/}
          {/*      <Statistic title="昨日活跃" value={teacherSummary?.active} />*/}
          {/*    </Col>*/}
          {/*  </Row>*/}
          {/*</div>*/}
        </div>

      </div>
    </Spin>
  );
}

export default Dashboard;
