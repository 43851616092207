import React, { useState } from 'react';

import { Button, Form, Input, message, Modal, Upload } from "antd";
import { UploadOutlined } from '@ant-design/icons';
import { PageAudioForm } from "../../form/PageForm";

export function BookPageModal(props: {
  form: PageAudioForm;
  onOk: (form: PageAudioForm) => void;
  onCancel: () => void;
}) {
  const {form, onOk, onCancel} = props;

  const [f, setF] = useState<PageAudioForm>(form);

  const uploadProps = {
    name: 'file[]',
    action: '/fs/',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info: any) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${ info.file.name } file uploaded successfully`);
        setF(f.amend('cover', info.file.response.filenames[0]));
      } else if (info.file.status === 'error') {
        message.error(`${ info.file.name } file upload failed.`);
      }
    },

  };

  const uploadProps1 = {
    name: 'file[]',
    action: '/fs/',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info: any) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${ info.file.name } file uploaded successfully`);
        setF(f.amend('audio', info.file.response.filenames[0]));
      } else if (info.file.status === 'error') {
        message.error(`${ info.file.name } file upload failed.`);
      }
    },

  };


  return (
    <Modal
      title={ '添加页面' }
      visible={ true }
      okText={ '确定' }
      cancelText={ '取消' }
      onCancel={ () => onCancel() }
      onOk={() => onOk(f)}
    >
      <Form
        layout={ 'vertical' }
        name="basic"
      >
        <Form.Item
          label="名称"
          required
        >
          <Input onChange={ (e) => setF(form.amend('title', e.target.value)) } value={ f.title }/>
        </Form.Item>
        <Form.Item
          label="封面"
        >

          <Upload { ...uploadProps }>
            <Button icon={ <UploadOutlined/> }>{ f?.cover ? f.cover : '选择上传的图片' }</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="音频"
        >

          <Upload { ...uploadProps1 }>
            <Button icon={ <UploadOutlined/> }>{ f?.audio ? f.audio : '选择上传音频' }</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          label="页码"
          required
        >
          <Input onChange={ (e) => setF(f.amend('number', +e.target.value)) } value={ f.number }/>
        </Form.Item>
        <Form.Item
          label="链接"

        >
          <Input onChange={ (e) => setF(f.amend('refLink', e.target.value)) } value={ f.refLink }/>
        </Form.Item>
      </Form>
    </Modal>
  );
}
