
export class WhitePhone {

  static fromJSON(data: WhitePhone) {

    return new WhitePhone(
      data.id,
      data.phone
    );
  }

  constructor(
    public readonly id: number,
    public readonly phone: string
  ) {

  }
}

export interface WhitePhoneJSON {
  readonly id: number;
  readonly phone: string;
}
